import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { useMap } from "react-leaflet";
import { Country } from "country-state-city";
import { LatLngLiteral } from "leaflet";
import React from "react";
import { Color } from "@material-ui/lab/Alert";
import * as Yup from "yup";
import "yup-phone-lite";
import { CountryCode } from "libphonenumber-js/types";
import { setStorageData } from "../../../../packages/framework/src/Utilities";

export interface CountryInterfaceData {
  name: string;
  ICountry: [];
  isoCode: string;
  flag: string;
  phonecode: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: {
    zoneName: string;
    gmtOffset: string;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
  };
}
export interface SelectEventType {
  target: {
    value: string | unknown;
  }
}

export interface SelectEventType2 {
  target: {
    value: {
      name: string;
      latitude: string | number;
      longitude: string;
      coords: string;
    }
  }
}
interface CountryMapValueType {
  name: string;
  latitude: string;
  longitude: string;
}
interface CountryMapInterface {
  id: string;
  countryName: string;
  countryUrl: string;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  message: object;
  data: object;
}

interface ErrorResponse {
  [key: string]: string;
}

interface InvalidResponseType {
  errors: ErrorResponse[];
  account: ValidResponseType;
  message: object;
  data: object;
}

export interface CountryCodeInterFace {
  countryCode: string;
  name: string;
  dialCode: string;
  format: string;
}

export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  firstName: string;
  lastName: string;
  contactNumber: string;
  cityValue: string;
  messageValue: string;
  countryData: Array<CountryInterfaceData>;
  countryValue: string;
  countryMapData: Array<CountryInterfaceData>;
  countryMapValue: string | unknown;
  latitude: number;
  longitude: number;
  isSucces: boolean;
  countryError: boolean;
  countryErrorTxt: string;
  coords: [number, number];
  email: string;
  emailValue: string;
  emailError: boolean;
  emailErrorTxt: string;
  contactNumberError: boolean;
  contactNumberErrorTxt: string;
  cityError: boolean;
  cityErrorTxt: string;
  messageError: boolean;
  messageErrorTxt: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  toggleDrawer: boolean;
  phoneCountryCode: CountryCode;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  contactFormApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      firstName: "",
      lastName: "",
      contactNumber: "",
      cityValue: "",
      messageValue: "",
      countryData: Country.getAllCountries() as never,
      countryValue: "",
      countryMapData: Country.getAllCountries() as never,
      countryMapValue: "none",
      latitude: 0,
      longitude: 0,
      isSucces: false,
      countryError: false,
      countryErrorTxt: "",
      coords: [20.5937, 78.9629],
      email: "",
      emailValue: "",
      emailError: false,
      emailErrorTxt: "",
      contactNumberError: true,
      contactNumberErrorTxt: "",
      cityError: false,
      cityErrorTxt: "",
      messageError: false,
      messageErrorTxt: "",
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      toggleDrawer: false,
      phoneCountryCode: {} as CountryCode
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    window.scroll({
      top: 0,
      left: 0
    })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId);
      };
      if (responseJson && responseJson.errors) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      };
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  handleContactNumberChange = (event: string, country: CountryCodeObj,
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
  ) => {
    this.setState({ contactNumber: event, phoneCountryCode: country?.countryCode?.toUpperCase() as CountryCode })
    SetFieldValue("contactNumber", event)
  };

  navigateToAnyPage = (navigateTo: string) => {
    this.props.navigation.navigate(navigateTo)
  };

  handleCountryMapChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (typeof event.target.value === "object" && event.target.value !== null) {
      const { name, latitude, longitude } = event.target.value as CountryMapValueType;
      const latValue = parseFloat(latitude);
      const lngValue = parseFloat(longitude);
      if (!isNaN(latValue) && !isNaN(lngValue)) {
        this.setState({
          latitude: latValue,
          longitude: lngValue,
          coords: [latValue, lngValue]
        });
      }
    }
    this.setState({ countryMapValue: event.target.value });
  };

  handleCountryChange = (event: SelectEventType, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void ) => {
    this.setState({ countryValue: event.target.value as string });
    SetFieldValue("countryValue", event.target.value as string)
  };

  setViewOnClick({ coords }: { coords: [number, number] }) {
    const [latitude, longitude] = coords;
    const latLng: LatLngLiteral = { lat: latitude, lng: longitude };
    const mapValue = useMap();
    mapValue.setView(latLng, mapValue.getZoom());
    return null;
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToLoginPage = () => {
    const navigation = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToRegistration = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  postFormApi = async () => {
    const formdata = new FormData();
    formdata.append("first_name", this.state.firstName);
    formdata.append("last_name", this.state.lastName);
    formdata.append("email", this.state.emailValue);
    formdata.append("country", this.state.countryValue as string);
    formdata.append("city", this.state.cityValue);
    formdata.append("phone_number", this.state.contactNumber);
    formdata.append("message", this.state.messageValue);

    this.contactFormApiCallId = await this.apiCall({
      type: configJSON.formDatatype,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.formAPIEndPoint,
      body: formdata
    });
  };

  apiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      "Content-Type": contentType,
      token: "",
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  apiSucessCall = async (apiRequestCallId: string) => {
    if (apiRequestCallId === this.contactFormApiCallId) {
      this.helplineSucessCallBack();
    }
  };

  apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.contactFormApiCallId) {
      this.helplineFailureCallBack(responseJson);
    }
  };

  helplineSucessCallBack = () => {
    this.setState({
      isSucces: true
    });
  };

  helplineFailureCallBack = (responseJson: InvalidResponseType) => {
    const errorKey: string = Object.keys(responseJson.errors[0])[0];
    this.setState({
      isAlert: true,
      alertMsg: responseJson.errors[0][errorKey],
      alertType: "error",
    });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };


  ContactUsSchema = () => {
    const FirstAndLastNameRegx = /^[A-Za-z\s]+$/
    return Yup.object().shape({
      firstName: Yup.string().matches(FirstAndLastNameRegx, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').trim('First name cannot be empty'),
      lastName: Yup.string().matches(FirstAndLastNameRegx, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').trim('First name cannot be empty'),
      emailValue: Yup.string().email("Please enter valid email").required("Email is required"),
      countryValue: Yup.string().required("Please select a country."),
      cityValue: Yup.string().required("City is required."),
      contactNumber: Yup.string().phone(this.state.phoneCountryCode,"Contact number is invalid.").required("Contact number is required."),
      messageValue: Yup.string().max(1000, 'maximum 1000 characters are allowed').required("Message is required."),
    })
  }

  handelTextFieldSet = (
    e:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
  ) =>{
    SetFieldValue(e.target.name, e.target.value)
    this.setState((prev)=>({
      ...prev, [e.target.name]: e.target.value
    }))
  }
  // Customizable Area End
}
