import { Box, Typography, styled, Modal, Card, CardContent, Button } from '@material-ui/core';
import React, { Component } from 'react'
import IconOne from './sidebarMenuIcons/IconOne';
import IconTwo from './sidebarMenuIcons/IconTwo';
import IconThree from './sidebarMenuIcons/IconThree';
import IconFour from './sidebarMenuIcons/IconFour';
import IconFive from './sidebarMenuIcons/IconFive';
import IconSix from './sidebarMenuIcons/IconSix';
import IconSeven from './sidebarMenuIcons/IconSeven';
import IconEight from './sidebarMenuIcons/IconEight';
import IconNine from './sidebarMenuIcons/IconNine';
import IconTenth from './sidebarMenuIcons/IconTenth';
import IconEleven from './sidebarMenuIcons/IconEleven';
import IconTwelve from './sidebarMenuIcons/IconTwelve';
import IconThirteen from './sidebarMenuIcons/IconThirteen';
import IconFourteen from './sidebarMenuIcons/IconFourteen';
import IconFifteen from './sidebarMenuIcons/IconFifteen';
import IconLogout from './sidebarMenuIcons/IconLogout';
import IconMenu from './sidebarMenuIcons/IconMenu';
import { getStorageData, removeStorageData } from 'framework/src/Utilities';
import CloseIcon from '@mui/icons-material/Close';
import { isDate } from 'date-fns';

interface SideMenuBarPropsType {
  activeComponent:string;
  navigationToAnyPage:(pageName: string) => void;
}

const counselleorRole = [
  {
    componentName: "DashBoard",
    icon: IconOne,
    header: "Dashboard"
  },
  {
    componentName: "",
    icon: IconTwo,
    header: "Programs & Schools"
  },
  {
    componentName: "LeadManagement",
    icon: IconFive,
    header: "Students"
  },
  {
    componentName: "RequestManagementTableWeb",
    icon: IconSix,
    header: "Applications"
  },
  {
    componentName: "",
    icon: IconFourteen,
    header: "Blank Application Forms and documents"
  },
  {
    componentName: "",
    icon: IconEleven,
    header: "GIC  Account (New)"
  },
  {
    componentName: "",
    icon: IconMenu,
    header: "GIC  Account (New)"
  },
  {
    componentName: "",
    icon: IconFifteen,
    header: "About Updates"
  },
  {
    componentName: "LandingPage",
    icon: IconLogout,
    header: "Logout"
  }
]

const agentRole = [
  {
    componentName: "DashBoard",
    icon: IconOne,
    header: "Dashboard"
  },
  {
    componentName: "",
    icon: IconTwo,
    header: "Programs & Schools"
  },
  {
    componentName: "",
    icon: IconThree,
    header: "Wallet"
  },
  {
    componentName: "",
    icon: IconFour,
    header: "Training request"
  },
  {
    componentName: "LeadManagement",
    icon: IconFive,
    header: "Students"
  },
  {
    componentName: "RequestManagementTableWeb",
    icon: IconSix,
    header: "Applications"
  },
  {
    componentName: "",
    icon: IconSeven,
    header: "Payment"
  },
  {
    componentName: "",
    icon: IconEight,
    header: "Promotional Commisions and incentives"
  },
  {
    componentName: "",
    icon: IconNine,
    header: "Manage Staff"
  },
  {
    componentName: "",
    icon: IconTenth,
    header: "Teams"
  },
  {
    componentName: "",
    icon: IconEleven,
    header: "GIC  Account (New)"
  },
  {
    componentName: "",
    icon: IconTwelve,
    header: "ILELTS Test  booking (New)"
  },
  {
    componentName: "",
    icon: IconThirteen,
    header: "Reminder"
  },
  {
    componentName: "",
    icon: IconFourteen,
    header: "Blank Application Forms and documents"
  },
  {
    componentName: "",
    icon: IconMenu,
    header: "GIC  Account (New)"
  },
  {
    componentName: "",
    icon: IconFifteen,
    header: "About Updates"
  },
  {
    componentName: "LandingPage",
    icon: IconLogout,
    header: "Logout"
  }
]

export default class SideMenuBar extends Component<SideMenuBarPropsType> {
    state = {
      openDrawer:false,
      tempData:agentRole,
      isLogout:false
    }

    async componentDidMount() {
      const UserRole = await getStorageData("role")
      this.setState({
        tempData: UserRole === "counsellor" ? counselleorRole : agentRole,
      })
    }

    changeDrawerState = () => {
     this.setState({openDrawer: !this.state.openDrawer})
    }

    navigateToPage = (pageName:string) => {
      if(pageName) {
        if(pageName === "LandingPage"){
          this.setState({isLogout: true})
        }
        else {
          this.props.navigationToAnyPage(pageName)
          this.setState({openDrawer: false})
        }
      }
    }

    renderDrawer = () => {
        return (
<SideMenuBarBigBox>
  {this.state.tempData.map((item, index) => (
    <DrawerBox key={index} style={{
    backgroundColor: item.componentName === this.props.activeComponent ?  "#34D3994D" : "white"}}
    onClick={()=> this.navigateToPage(item.componentName)}>
    <IconBoxBigSize title={item.header}>
              <item.icon color={item.componentName === this.props.activeComponent ?  "#34D399" : "black"}/>
    </IconBoxBigSize>
      <IconTxt style={{color:item.componentName === this.props.activeComponent ?  "#34D399" : "black"}}>
        {item.header}
      </IconTxt>
    </DrawerBox>
  ))}
  <DrawerBigBoxIcon onClick={this.changeDrawerState}>
    {"<"}
  </DrawerBigBoxIcon>
</SideMenuBarBigBox>
        )
    }

    renderSmallScreen = () => {
        return (
<SideMenuBarBox>
  {this.state.tempData.map((item, index) => (
    <IconBoxSmallSize
    key={index} style={{
    backgroundColor: item.componentName === this.props.activeComponent ?  "#34D3994D" : "white"}}
     title={item.header} onClick={()=>this.navigateToPage(item.componentName)}>
              <item.icon color={item.componentName === this.props.activeComponent ?  "#34D399" : "black"}/>
    </IconBoxSmallSize>
  ))}
  <DrawerBoxIcon onClick={this.changeDrawerState}>
    {">"}
  </DrawerBoxIcon>
</SideMenuBarBox>
        )
    }

    logoutFromApp = () => {
      this.setState({openDrawer: false, isLogout:false})
      this.props.navigationToAnyPage("LandingPage")
      removeStorageData('authToken');
      removeStorageData('profileData');
      removeStorageData('deviceToken');
      removeStorageData('role');
      removeStorageData('catalogListData');
      removeStorageData('token');
      removeStorageData('userDetails');
    }

    handleLogoutPopUp = () => {
      this.setState({isLogout: false})
    }

    renderLogout = () => {
      return (
        <DeleteModalBox open={this.state.isLogout} onClose={this.handleLogoutPopUp}>
          <Card className="cardBox">
            <CardContent className="cardContent">
              <Box className="box">
              <CloseIcon className="closeIcon" onClick={this.handleLogoutPopUp} />
              </Box>
              <Typography className="deleteTxtBtn marginTop30">
               Logout from the account
              </Typography>
              <Typography className="confirmationTxt">
              Are you sure you want to logout from the account?
              </Typography>
              <Box className="deleteBtnBottomBox">
  
              <Button className="deleteModalInsideBtn" onClick={this.logoutFromApp}>
                  Logout
                </Button>
  
                <Button className="confirmModalInsideBtn" onClick={this.handleLogoutPopUp}>
                  Go Back
                </Button>
              </Box>
            </CardContent>
          </Card>
        </DeleteModalBox>
      );
    };
    render() {
        return (
          <MainBox >
            {this.state.openDrawer ? this.renderDrawer() : this.renderSmallScreen()}
            {this.state.isLogout && this.renderLogout()}
          </MainBox>
        )
    }
}

const MainBox = styled(Box)({
  display: "flex",
  width: "64px",
  backgroundColor: 'white',
  borderRight: '1px solid #E2E8F0',
  boxShadow: '1',
  top: 85,
  bottom: 0,
  overflowY: "auto",
  position: "fixed",
  zIndex: 1000,
     '::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
      display: 'none',
    },
    scrollbarWidth: 'none',
});

const SideMenuBarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: 'white',
  alignItems: 'center',
  padding: "10px 0px",
  width: "100%"
})

const SideMenuBarBigBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    boxShadow: '1',
    position: 'fixed',
    borderRight: '1px solid #E2E8F0',
  padding: "10px 0px",
  maxHeight: "80%",
  overflowY: "scroll",
      '@media (max-width:960px)': {
      width: '250px',
    },
    '@media (min-width:720px)': {
      width: '306px',
    },
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
      display: 'none',
    },
    scrollbarWidth: 'none',
  });
  

const IconBoxBigSize = styled(Box)({
    cursor:"pointer",
    width:"32px",
    margin:"0 4px"
})

const IconBoxSmallSize = styled(Box)({
    cursor:"pointer",
    margin:"5px 0",
    borderRadius:"6px",
    padding:"2px",
})

const DrawerBox = styled(Box)({
    margin:"10px 0",
    width:"230px",
    display:"flex",
    alignItems:"center",
    padding:"5px",
    borderRadius:"6px",
    cursor:"pointer",
})

const IconTxt = styled(Typography)({
    margin:"0 4px",
    fontWeight:500,
    fontFamily:"Plus Jakarta Sans",
    fontSize:"14px",lineHeight:"17.64px",
})

const DrawerBoxIcon = styled(Box)({
    width:"24px",
    height:"24px",
    position:"fixed",
    fontWeight:900,
    cursor:"pointer",
    boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    background:"white",
    borderRadius:"20%",
    display:"flex",
    justifyContent:"center",
    top:"50%",
        left:"50px",
        fontFamily: 'Plus Jakarta Sans'
})

const DrawerBigBoxIcon = styled(Box)({
    width:"24px",
    height:"24px",
    position:"fixed",
    fontWeight:900,
    cursor:"pointer",
    transform: "translateY(-50%)",
    boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    background:"white",
    borderRadius:"20%",
    display:"flex",
    justifyContent:"center",
    fontFamily: 'Plus Jakarta Sans',
    '@media (max-width:960px)': {
      top:"50%",
      left:"240px",
    },
    '@media (min-width:720px)': {
      top:"50%",
      left:"295px",
    },
})

const DeleteModalBox = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .cardBox": {
    maxWidth: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
  },
  "& .cardContent": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  "& .box": {
    position: "relative"
  },
  "& .closeIcon": {
    position: "absolute",
    right: "20px",
    top: "20px"
  },
  "& .deleteTxtBtn": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding:"0 16px"
  },
  "& .deleteTxtBtnBranch": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
  },
  "& .marginTop30":{marginTop: "30px"},
  "& .confirmationTxt": {
    color: "#212121",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "18px",
    fontWeight: "400",
    padding:"0 16px",
    paddingBottom:"24px"
  },
  "& .deleteBtnBottomBox": {
    width: "400px",
    height: "80px",
    padding: "24px 0px 0px 0px",
    gap: "16px",
    borderRadius: "10px",
    borderTop: "1px solid #E2E8F0",
    background: "#FFFFFF",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center"
  },
  "& .deleteModalInsideBtn": {
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #BB393D",
    boxShadow: "0px 4px 4px 0px #00000040",
    color:"#BB393D"
  },
  "& .confirmModalInsideBtn":{
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#BB393D",
    color: "#FFFFFF",
    marginRight:"24px"
  }
});