import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import DasbordViewMoreController from "./DasbordViewMoreController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import { Backdrop, Card, Grid, TextField, Typography } from "@material-ui/core";
export const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DatePicker from "react-datepicker";

// Customizable Area End

export default class DasbordViewMore extends DasbordViewMoreController {
  // Customizable Area Start
  CustomInput = ({
    value,
    onClick,
  }: {
    value?: string;
    onClick?: () => void;
  }) => (
    <DateInputBox onClick={onClick} data-test-id="dateInputId">
      <input type="text" value={value} readOnly placeholder="Choose Period" />
      <CalendarWrapper>
        <CalendarTodayIcon />
      </CalendarWrapper>
    </DateInputBox>
  );

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DasbordViewMoreStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />

          <Box className="mainBar">
            <BoxButton>
              <Box><StudentTxt >{configJSON.recentUpdateTitle}</StudentTxt></Box>
              <Box className="datePickers">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleDateChange}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  selectsRange
                  customInput={<this.CustomInput />}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  data-test-id="startDateId"
                />
              </Box>
            </BoxButton>
            <BoxButton>
              <Box className="BoxContainer">
                {
                  this.paginateData(this.state.CardDetailsData, this.state.page, 4).map((item, index) => {
                    return (
                      <AnyUpdateBox>
                      <Grid container>
                        <Grid item sm={12} md={4} lg={3} className="imgGrid" >
                          <Box className="imgGridInner">
                            <img src={item.imageUrl} height="100%" />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={8} lg={9} xs={12}>
                          <Box className="innerUpdateMainBox">
                            <Box className="upperTxtBox">
                            <Button data-test-id={`readMoreId${index}`} className="readMoreBtn" onClick={()=>this.handleOpenClose(item.points)}> {configJSON.readmoreTxt}</Button>
                            <Typography className="announcementType" >{item.AnnouncementType}</Typography>
                            <Typography className="hideText">{item.AnnouncementType}</Typography>
                            </Box>
                            <Box className="countryList">
                            <Typography className="announcementType">{configJSON.applicableCountriesText}</Typography>
                                 {item.ContryName.map((country: string, index: number) => (
                    <Typography key={index} className="countryText">
                      {country}
                      {index < item.ContryName.length - 1 && ','}
                    </Typography>
                  ))}
                            </Box>
                            <Typography style={webStyles.defaultTxtBold} className="recentUpdateHeading">
                            {item.heading}
                            </Typography>
                            <Box className="listPadding">
                               <Typography
                    className="listTxt"
                    dangerouslySetInnerHTML={{
                      __html: item.points
                    }}
                  >
                  </Typography>
                                
                            </Box>
                            <Box className="belowBox">
                              <Typography style={webStyles.italicStyle} data-test-id={`postedOnId${index}`}>
                                {configJSON.postedOnDate}{item.PublishDate}
                              </Typography>
                            
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </AnyUpdateBox>    
                     )
                  })
                }
                <Box className="PaginationContainer" >
                  <Pagination
                    data-test-id="countTestId"
                    count={this.renderCount()}
                    page={this.state.page}
                    onChange={this.handleChangePage}
                  />
                </Box>
              </Box>
            </BoxButton>
          </Box>
        </LeadBox>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.updateModalOpen}
        onClose={()=>this.handleOpenClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.updateModalOpen}>
          <InnerModalBox>
            <Box
              className="modalTextBox"
            >
              <IconButton
                onClick={() => this.handleOpenClose()}
                className="iconCloseBtn"
                data-test-id="modalTestId"
              >
                <CloseIcon
                  aria-label="close"
                />
              </IconButton>
              <Typography
                className="modalItemTxt"
                data-test-id="renderedModalId"
                dangerouslySetInnerHTML={{ __html: this.state.updateValue }}
              />
            </Box>

          </InnerModalBox>
        </Fade>
      </Modal>
      </DasbordViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const DasbordViewMoreStyle = styled(Box)({
  "& .BoxContainer": {
    width: "100%"
  },
  "& .CardContainer": {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    padding: "20px 5px",
    marginTop: "10px",
    "@media only screen and (max-width: 425px) ": {
      padding: "0 5px"
    },
  },
  "& .CardImage": {
    width: "150px",
    borderRadius: "200px",
    height: "150px",
    maxHeight: '200px'
  },
  "& .ImageContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .ContentContainer": {
    padding: "10px 0px",
    paddingRight: "20px",
    "& .HeadingContainer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
      marginTop: "10px",
      "& .Heading": {
        color: "#400a9d",
      },
      "@media only screen and (max-width: 600px) ": {
        display: "block",
        padding: "0 20px"
      },
    },
    "& .EditorContent": {
      maxHeight: "200px",
      overflowY: "auto"
    },
    "& .ApplicableCountryContainer": {
      display: "flex",
      "& .ApplicableCountryClass": {
        fontWeight: "bold",
        marginBottom: "4px",
        marginTop: "14px",
        paddingTop:"10px"
      }
    },
    "& .ReadMoreContainer": {
      margin: "5px 0",
      "& .readMoreBtn": {
        fontFamily: "Plus Jakarta Sans",
        color: "#1976d2",
        textTransform: "unset",
        padding: "0px !important",
        fontSize: "16px",
        fontWeight: "bold"
      },
      "& .AnnouncementType": {
        fontWeight: "700",
         fontSize: "16px",
      }
    }
  },
  "& .PaginationContainer": {
    display: "flex",
    justifyContent: "center",
  }
})

const InnerModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .iconCloseBtn":{
    position: "absolute",
     top: 0, 
     right: 0 
  },
  "& .modalTextBox":{
    backgroundColor: "#FFFF",
    position: "relative",
    borderRadius: "5px",
    padding: "30px 60px",
    cursor: "pointer",
    "@media (max-width: 600px)": { 
      padding: "20px 30px",
    },
  },
  "& .modalItemTxt": {  
    maxHeight: "500px",
    overflowY: "scroll",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    }
  }
});

const BoxButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "10px"
  }
})

const StudentTxt = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px !important",
  fontWeight: '700 !important'
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    marginLeft: "64px",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
    "& .datePickers": {
      display: "flex",
      gap: "10px"
    }
  },
  "& .defaultTxtBold": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  }
});
const DateInputBox = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  padding: "8px 10px",
  width: "210px",
  background:"#fff",
  "& input": {
    border: "none",
    outline: "none",
    width: "100%",
    paddingRight: "30px", 
  },
});

const CalendarWrapper = styled(Box)({
  position: "absolute",
  right: "10px", 
  color: "#757575", 
});
const AnyUpdateBox = styled(Box)({
  width: "100%",
  borderRadius: "8px",
  marginBottom:"15px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  backgroundColor: "white",
  padding: "20px",
  boxSizing: "border-box",
  "& .imgGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .innerUpdateMainBox": {
    maxWidth: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    "@media (max-width: 599px)": {
      maxWidth: "395px"
    }
  },
  "& .listPadding": {
    padding: "10px 0px",
    fontSize: "14px",
    color: "#212121",
    lineHeight: "17.64px",
    opacity: "80%",
    width: "100%"
  },
  "& .listTxt": {
    fontFamily: "Plus Jakarta Sans",
    maxHeight: "100px",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin",
    width: "100%",
    "&::-webkit-scrollbar": {
        width: "5px" 
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1"
    }
  },
  "& .belowBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media screen and (max-width:500px)": {
      flexDirection: "column",
      gap: "10px"
    }
  },
  "& .recentUpdateHeading":{
    color: "#2A0066",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:700
  },
  "& .upperTxtBox":{
    display:"flex", justifyContent: "space-between", width:"100%",
    alignItems:"center",
    "@media screen and (max-width:500px)": {
      flexDirection: "column",
      gap: "10px"
    }
  },
  "& .countryList": {
    padding:"20px 0",
    display:"flex",
    gap:"2px",
    flexWrap:"wrap",
    width: "100%"
  },
  "& .readMoreBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#1976d2",
    textTransform: "unset",
    padding: "0px !important",
    fontSize: "16px",
    fontWeight: "bold"
  },
  "& .announcementType": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    textAlign: "center",
    padding: "0px !important",
    fontSize: "16px",
    fontWeight: "bold",
    marginRight:"10px"
  },
  "& .hideText": {
    visibility: "hidden"
  },
  "& .countryText": {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
  },
  "& .imgGridInner": {
    width: "147.48px", 
    height: "145.19px", 
    padding: "10px 0px",
    "& img":{
      width:"100%"
    }
  }
})


const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  defaultTxt: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px"
  },
  defaultTxtBold: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  italicStyle: {
    fontWeight: 500,
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "italic",
    color: "#212121",
    opacity: "40%"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  paddingRight: {
    paddingRight: "40px"
  },
  cardContent: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "1",
    "&. MuiTypography-body1": {
      lineHeight: "1"
    }
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B"
  },
  fontBig: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#64748B"
  },
  secondCardHeader: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#212121"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  regularFont: { fontSize: "14px", color: "#212121", lineHeight: "17.64px", opacity: "80%" },
  listPadding: { padding: "0 11px" },
  backgroundColor: {
    fontFamily: "Plus Jakarta Sans",
    background: "#F7F7F7"
  },
  paddingAll: {
    padding: "20px"
  }
};

// Customizable Area End