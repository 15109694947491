import React from "react";
// Customizable Area Start
import {
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ViewProfileController from "./ViewProfileController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import { Card, Collapse, Grid, Typography, Button } from "@material-ui/core";
export const configJSON = require("./config");
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CalenderIcon, Phone_Icon, SmsIcon } from './assets';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Customizable Area End

export default class ViewProfile extends ViewProfileController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ViewMoreStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />
          <Box className="mainBar">
            <Box className="BoxContainer">
              <Button className="StudentIdText" startIcon={<ArrowBackIosIcon />} >
                Student ID - <span className="BoldText">3817650</span>
              </Button>
            </Box>
            <Box>
              <Collapse in={false} collapsedSize={500}>
                <Card className="StudentDetailsContainer">
                  <Box className="StundetGenralDetails">
                    <Grid container>
                      <Grid item xs={2}>
                        <Box className="ProfilePicContainer" >
                          <img
                            src={`https://picsum.photos/seed/picsum/100/100`}
                            className="StudentProfilePic" />
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box>
                          <Typography variant="h6">
                            Student Name
                          </Typography>
                          <Box className="DetailsCotainer">
                            <Box className="DetailsBox">
                              <Box>
                                <Typography variant="body1">
                                  Student ID -
                                </Typography>
                              </Box>
                              <Box><Typography variant="body1">
                                1506872
                              </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="DetailsCotainer">
                            <Box className="DetailsBox" >
                              <Box>
                                <Typography variant="body1">
                                  Passport No -
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1">
                                  1506872
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box className="SecondDetailsContainer">
                          <Box>
                            <Box className="IconAndDetailsContainer" >
                              <Box>
                                <img src={Phone_Icon} />
                              </Box>
                              <Box>
                                <Typography variant="body1">

                                  +91 8283091451
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="IconAndDetailsContainer" >
                              <Box>
                                <img src={CalenderIcon} />
                              </Box>
                              <Box>
                                <Typography variant="body1">
                                  12-July-1996
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="IconAndDetailsContainer" >
                              <Box>
                                <img src={SmsIcon} />
                              </Box>
                              <Box>
                                <Typography variant="body1">
                                  gurneetkaur05042003@gmail.com
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Button variant="outlined" size="large" startIcon={<EditIcon />} className="EditButton">
                              Edit Profile
                            </Button>
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                    <Box className="PersonalInfoContainer" >
                      <Grid container className="PersonalInfoGridContainer">
                        <Grid item xs={6}>
                          <Grid container className="DetailsContainer">
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Gender
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Male
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Marital Status
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Marital Status
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Nationality
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Indian
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Emergency Contact Details
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="Title" >
                                <Box className="NameAndDetails">
                                  <Box>
                                    <Typography variant="body1" className="smallTitle" >
                                      Name:
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1" className="BoldTitle">
                                      Rajesh
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="NameAndDetails">
                                  <Box>
                                    <Typography variant="body1" className="smallTitle">
                                      Mobile:
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1" className="BoldTitle">
                                      +91-9876543210
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="NameAndDetails">
                                  <Box>
                                    <Typography variant="body1" className="smallTitle">
                                      Email:
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1" className="BoldTitle">
                                      rajesh@gmail.com
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container className="DetailsContainer">
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Residence
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Canada
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Students Address
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Nova Scotia, Lahave
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Associated Branch
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Aboard Study
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Visa refusal
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                No refusals
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title">
                                Travel history
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" className="Title BoldTitle">
                                Not traveled abroad
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Card>
              </Collapse>
              <Box>
                <Card className="StudentDetailsContainer">
                  <Box className="StundetGenralDetails">
                    <Tabs value={0}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example" >
                      <Tab label="Student Documents" />
                      <Tab label="Applications" />
                      <Tab label="Shortlisted" />
                      <Tab label="Assigned Staff" />
                      <Tab label="Transactions" />
                    </Tabs>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
        </LeadBox>
      </ViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ViewMoreStyle = styled(Box)({
  "& .BoxContainer": {
    width: "100%",
    marginBottom: "10px",
    "& .StudentIdText": {
      textTransform: "capitalize",
      "& .MuiSvgIcon-root": {
        fontSize: "16px"
      },
      "& .BoldText": {
        fontWeight: "bold"
      }
    }
  },
  "& .StudentDetailsContainer": {
    padding: '20px',
    "& .StundetGenralDetails": {
      backgroundColor: "#F8FAFC",
      "& .ProfilePicContainer": {
        "& .StudentProfilePic": {
          widht: "150px",
          height: "150px",
          borderRadius: "50%"
        }
      },
      "& .DetailsCotainer": {
        "& .DetailsBox": {
          display: 'flex',
          gap: "10px"
        }
      }
    },
    "& .SecondDetailsContainer": {
      display: 'flex',
      justifyContent: 'space-between',
      "& .IconAndDetailsContainer": {
        display: "flex",
        gap: '10px',
        alignItems: "center"
      },
      "& .EditButton": {
        backgroundColor: '#D6F6EB',
        border: '1px solid #34D399',
        color: '#059669'
      }
    }
  },
  "& .PersonalInfoContainer": {
    marginTop: "20px",
    "& .PersonalInfoGridContainer": {
      "& .DetailsContainer": {
        "& .MuiGrid-item": {
          borderBottom: '1px solid #E2E8F0',
          "& .Title": {
            marginBottom: "10px",
            marginTop: "10px",
          },
          "& .BoldTitle": {
            fontWeight: "bold"
          }
        },
        "& .NameAndDetails": {
          display: "flex",
          gap: "5px",
          alignItems: "center",
          "& .smallTitle": {
            minWidth: "70px"
          }
        }
      }
    }
  }
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    marginLeft: "64px",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
  },
});


// Customizable Area End